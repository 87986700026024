import React, {Component} from 'react';
import './App.css'
import {Layout, Header, Navigation, Drawer, Content} from 'react-mdl'
import Main from './components/main'
import {Link} from 'react-router-dom'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-146494479-1');
ReactGA.pageview(window.location.pathname + window.location.search);


class App extends Component {
    closeDrawer = () => {
        const sideNav = document.querySelector('.mdl-layout');
        sideNav.MaterialLayout.toggleDrawer();
    };

    render() {
        return (
            <div className="demo-big-content">
                <Layout fixedHeader>
                    <Header className="app-color" title=" " scroll>
                        <Navigation className="desktop-nav">
                            <Link to="/">Home</Link>
                            {/*<Link to="/projects">Projects</Link>*/}
                            {/*<Link to="/resume">Resume</Link>*/}
                            {/*<a href="https://stackoverflow.com/story/jeremiahogutu" target='_blank'*/}
                            {/*   rel="noopener noreferrer">Resume</a>*/}
                            <Link to="/contact">Contact</Link>
                        </Navigation>
                    </Header>
                    <Drawer title="Jeremiah Ogutu" onClick={this.closeDrawer}>
                        <Navigation>
                            <Link to="/">Home</Link>
                            {/*<Link to="/projects">Projects</Link>*/}
                            {/*<Link to="/resume">Resume</Link>*/}
                            {/*<a href="https://stackoverflow.com/story/jeremiahogutu" target='_blank'*/}
                            {/*   rel="noopener noreferrer">Resume</a>*/}
                            <Link to="/contact">Contact</Link>
                        </Navigation>
                    </Drawer>
                    <Content>
                        <div className="page-content">
                            <Main/>
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

export default App;
