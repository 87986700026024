import React, {Component} from 'react';
import './resume.css'
import {Grid} from "react-mdl";
import glogo from './glogo.svg';
import flatiron from './flatiron.png';
import animatedProfile from './profile_animated.png'
import western from './western.gif'

class Resume extends Component {
    calculateTime = () => {
        const today = new Date();
        const startDate = new Date(2017, 8, 1);
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();

        const numberOfMonths = (currentYear - startYear) * 12 + (currentMonth - startMonth) + 1;
        const yearDiff = Math.floor(numberOfMonths / 12);
        const monthDiff = numberOfMonths % 12;
        return {
            year: yearDiff,
            month: monthDiff
        }
    };

    render() {
        return (
            <div style={{width: '100%', background: '#fff'}}>
                <section id="cd-timeline" className="cd-container">
                    <div className="cd-timeline-block">
                        <div className="cd-timeline-img cd-picture">
                            <i className="fa fa-suitcase" style={{color: '#000'}}/>
                        </div>

                        <div className="cd-timeline-content">
                            <Grid className='mdl-grid--no-spacing'>
                                <div className="timeline-content-info">
                                    <Grid className="mdl-grid--no-spacing timeline-content-info-title" style={{
                                        alignItems: 'center',
                                        borderBottom: '1px solid #e1e1e1',
                                        paddingBottom: '5px'
                                    }}>
                                        <p style={{color: '#6a737c', margin: '0', fontSize: '12px'}}>Position • Sep 2017 → Current ({this.calculateTime().year} year, {this.calculateTime().month - 1} months)</p>
                                    </Grid>
                                </div>
                                <div className='mdl-cell mdl-cell--4-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '5px'}}>
                                    <img src={glogo} alt="" className='resume-logo'/>
                                </div>
                                <div className='mdl-cell mdl-cell--12-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '10px'}}>
                                    <h2 className='resume-text'>Software Developer At <a
                                        href="https://www.grobo.io" target="_blank" rel="noopener noreferrer"
                                        style={{color: '#0077cc', textDecoration: 'none'}}>Grobo
                                        Inc.</a>
                                    </h2>
                                    <ul className='resume-sub-text '>
                                        <li>Led development of the native app for both Android and IOS via React Native frontend and Rails API backend which successfully launched on both google play store and App store.</li>
                                        <li>Developed web app with full CRUD functionality based on React front-end, Redux for state management and Rails api backend.</li>
                                        <li>Maintained AWS RDS database, tuned parameters and queries for effective performance</li>
                                        <li>Integrated unit testing using JEST and Enzyme for React and NodeJS.</li>
                                        <li>Extensive experience in designing and implementation of continuous integration, continuous delivery, continuous deployment through Jenkins.</li>
                                        <li>Expertise in AWS cloud services such as EC2, S3, ELB, Auto scaling, EBS, RDS, VPC, Subnets, Security Groups, Elastic IP, Internet Gateway, Route Tables, Route53, Cloud Watch, IAM, SQS, SNS and SES.</li>
                                        <li>Implemented Multi-AZ RDS Read Replica for scalability, performance enhancement and automatic recovery fail over.</li>
                                        <li>Trained machine learning model using Google AutoML and used it to identify various plant deficiencies to help reduce the load on customer support.</li>
                                        <li>Experienced in building, maintaining, securing and encrypting Mosquito MQTT broker to facilitate IOT communication between app and Grobo.</li>
                                        <li>Developed test software for various hardware components to ensure Grobo is functioning as expected before shipping to customers.</li>
                                        <li>Worked on slack bot automation for error reporting, application git status, schedule team meetings and birthday alerts.</li>
                                        <li>Refactored UI designs to deliver a consistent, modular and scalable UI.</li>
                                        <li>Extensive experience with version control systems like GIT.</li>
                                        <li>Strong debugging and problem-solving skills with excellent understanding of system development methodologies, techniques and tools.</li>
                                        <li>Maintained existing code base, including refactoring old code, updating to use newer technologies, bug fixing, updating functionality</li>
                                    </ul>
                                    <ul className="content-skills">
                                        <li>React</li>
                                        <li>Redux</li>
                                        <li>React Native</li>
                                        <li>Ruby/Rails</li>
                                        <li>Python</li>
                                        <li>Mysql</li>
                                        <li>Nginx</li>
                                        <li>AWS</li>
                                        <li>Docker</li>
                                        <li>Express</li>
                                        <li>Node js</li>
                                        <li>GraphQl</li>
                                        <li>Jest</li>
                                        <li>Detox</li>
                                        <li>Jenkins</li>
                                    </ul>
                                </div>
                            </Grid>
                        </div>
                    </div>

                    <div className="cd-timeline-block">
                        <div className="cd-timeline-img cd-movie">
                            <i className="fa fa-graduation-cap" aria-hidden="true" style={{color: '#000'}}/>
                        </div>

                        <div className="cd-timeline-content">
                            <Grid className='mdl-grid--no-spacing'>
                                <div className="timeline-content-info">
                                    <Grid className="mdl-grid--no-spacing timeline-content-info-title" style={{
                                        alignItems: 'center',
                                        borderBottom: '1px solid #e1e1e1',
                                        paddingBottom: '5px'
                                    }}>
                                        <p style={{color: '#6a737c', margin: '0', fontSize: '12px'}}>Education • May 2017 → Jul 2017 </p>
                                    </Grid>
                                </div>
                                <div className='mdl-cell mdl-cell--3-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '5px'}}>
                                    <img src={flatiron} alt="" className='resume-logo'/>
                                </div>
                                <div className='mdl-cell mdl-cell--12-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '10px'}}>
                                    <h2 className='resume-text'><a
                                        href="https://flatironschool.com/online-community-bootcamp" target="_blank" rel="noopener noreferrer"
                                        style={{color: '#0077cc', textDecoration: 'none'}}>Community Powered Bootcamp, Flatiron School</a>
                                    </h2>
                                    <p className='resume-text'>Immersive web development program in Ruby, Rails,
                                        Javascript, React and Redux, JQuery, Sinatra, Rack, ORM and Active Record,
                                        SQL</p>
                                    <ul className="content-skills">
                                        <li>Ruby</li>
                                        <li>Ruby On Rails</li>
                                        <li>JavaScript</li>
                                        <li>React js</li>
                                        <li>Redux</li>
                                        <li>Sinatra</li>
                                        <li>Rack</li>
                                        <li>Orm</li>
                                        <li>Mysql</li>
                                    </ul>
                                </div>
                            </Grid>
                        </div>
                    </div>

                    <div className="cd-timeline-block">
                        <div className="cd-timeline-img cd-picture">
                            <i className="fa fa-comments" aria-hidden="true" style={{color: '#000'}}/>
                        </div>

                        <div className="cd-timeline-content">
                            <Grid className='mdl-grid--no-spacing'>
                                <div className="timeline-content-info">
                                    <Grid className="mdl-grid--no-spacing timeline-content-info-title" style={{
                                        alignItems: 'center',
                                        borderBottom: '1px solid #e1e1e1',
                                        paddingBottom: '5px'
                                    }}>
                                        <p style={{color: '#6a737c', margin: '0', fontSize: '12px'}}>Website • May 2016 → Present </p>
                                    </Grid>
                                </div>
                                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: 0}}>
                                    <img src={animatedProfile} alt="" style={{width: '100%'}}/>
                                </div>
                                <div className='mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: 0}}>
                                    <h2 className='resume-text'><a
                                        href="https://jeremiahogutu.ca" target="_blank" rel="noopener noreferrer"
                                        style={{color: '#0077cc', textDecoration: 'none'}}>Jeremiah | Portfolio</a>
                                    </h2>
                                    <p className='resume-text'>Software Developer; self-motivated learner, constantly improving and developing my skills</p>
                                </div>
                            </Grid>
                        </div>
                    </div>
                    <div className="cd-timeline-block">
                        <div className="cd-timeline-img cd-location">
                            <i className="fa fa-graduation-cap" aria-hidden="true" style={{color: '#000'}}/>
                        </div>

                        <div className="cd-timeline-content">
                            <Grid className='mdl-grid--no-spacing'>
                                <div className="timeline-content-info">
                                    <Grid className="mdl-grid--no-spacing timeline-content-info-title" style={{
                                        alignItems: 'center',
                                        borderBottom: '1px solid #e1e1e1',
                                        paddingBottom: '5px'
                                    }}>
                                        <p style={{color: '#6a737c', margin: '0', fontSize: '12px'}}>Education • Sep 2013 → Apr 2015</p>
                                    </Grid>
                                </div>
                                <div className='mdl-cell mdl-cell--12-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '5px'}}>
                                    <img src={western} alt="" className='resume-logo' style={{paddingTop: '25px'}}/>
                                </div>
                                <div className='mdl-cell mdl-cell--9-col mdl-cell--8-col-tablet mdl-cell--4-col-phone'
                                     style={{margin: '10px'}}>
                                    <h2 className='resume-text'><a
                                        href="https://www.uwo.ca/" target="_blank" rel="noopener noreferrer"
                                        style={{color: '#0077cc', textDecoration: 'none'}}>Computer Science, Western University</a>
                                    </h2>
                                    <ul className="content-skills">
                                        <li>Java</li>
                                        <li>C++</li>
                                    </ul>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Resume;
