import React from 'react';
import {Switch, Route} from 'react-router-dom'
import Home from "./home/home";
import Contact from "./contact/contact";
import Projects from "./projects/projects";
import Resume from "./resume/resume";

const Main = () => (
    <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/projects" component={Projects}/>
        <Route path="/resume" component={Resume}/>
        <Route path="/contact" component={Contact}/>
    </Switch>
);

export default Main