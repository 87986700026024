import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl'
import './home.css'

class Home extends Component {
    render() {
        return (
            <div style={{width: '100%', margin: 'auto'}}>
                <Grid className="home" style={{height: 'calc(100vh - 64px)', alignItems: 'center'}}>
                    <Cell col={12}>
                        <div className="banner-text">
                            <h1>Jeremiah Ogutu</h1>
                            <p>Software Developer based in Waterloo, Ontario, Canada.</p>
                            <div className="social-container">
                                <ul className="social-icons">
                                    <li><a href="https://www.linkedin.com/in/jeremiah-o-6265b674/"
                                           className="social-square" target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-linkedin"/></a>
                                    </li>
                                    <li><a href="https://github.com/jeremiahogutu"
                                           className="social-square" target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-github"/></a></li>
                                    <li><a href="https://stackoverflow.com/users/8549772/jeremiah-ogutu"
                                           className="social-square" target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-stack-overflow"/></a></li>
                                    <li><a href="https://bitbucket.org/jeremiahogutu" className="social-square"
                                           target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-bitbucket"/></a></li>
                                </ul>
                                <ul className="social-icons">
                                    <li><a href="https://gitlab.com/jeremiahogutu" className="social-square"
                                           target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-gitlab"/></a></li>
                                    <li><a href="https://facebook.com/jeremiah.ogutu.923"
                                           className="social-square" target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-facebook"/></a></li>
                                    <li><a href="https://twitter.com/jeremiah_ogutu" className="social-square"
                                           target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-twitter"/></a></li>
                                    <li><a
                                        href="https://www.youtube.com/channel/UC2TEo0fUIRtiNkMyA9USprg?view_as=subscriber"
                                        className="social-square" target="_blank" rel="noopener noreferrer"><i
                                        className="fa fa-youtube"/></a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </Cell>
                </Grid>
            </div>
        )
    }
}

export default Home
