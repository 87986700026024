import React, { Component } from 'react';
import './contact.css'
import profile from './profile.png'
import protonmail from './protonmail-icon.svg'
import linkedin from './linkedin.svg'

class Contact extends Component {
    constructor () {
        super ()
        this.state = {
            visibility: false
        };
        this.toggleVisibility = this.toggleVisibility.bind(this)
    }
    toggleVisibility () {
        this.setState({
            visibility: !this.state.visibility
        })
    };
    render () {
        return (
            <div className="contact-area">
                <div className="contact">
                    <main>
                        <section>
                            <div className="content">
                                <img src={profile} aria-hidden alt="Profile image"/>
                                    <aside>
                                        <h1 style={{color: '#fff', marginTop: 0}}>Jeremiah Ogutu</h1>
                                        <p>Software Developer based in Waterloo, Ontario, Canada.</p>
                                    </aside>

                                    <button onClick={this.toggleVisibility} className={this.state.visibility ? 'active': null}>
                                        <span>Contact Me</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                             viewBox="0 0 48 48">
                                            <g className="nc-icon-wrapper" fill="#444444">
                                                <path d="M14.83 30.83L24 21.66l9.17 9.17L36 28 24 16 12 28z"></path>
                                            </g>
                                        </svg>
                                    </button>
                            </div>
                            <div className={this.state.visibility ? 'title active': 'title'}><p>Contact Me</p></div>
                        </section>
                    </main>

                    <nav className={this.state.visibility ? 'active': null}>
                        <a href="https://www.linkedin.com/in/jeremiah-o-6265b674/" className="facebook" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="icon">
                                <img src={linkedin} alt=""/>
                            </div>

                            <div className="content">
                                <h1  style={{margin: 0, color: '#007ab9'}}>Linkedin</h1>
                                <span>Jeremiah Ogutu</span>
                            </div>

                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                 viewBox="0 0 48 48">
                                <g className="nc-icon-wrapper" fill="#444444">
                                    <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"></path>
                                </g>
                            </svg>
                        </a>

                        <a href="mailto:jogutu@pm.me?subject=subject" className="gmail" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="icon" style={{borderRadius: 'initial'}}>
                                <img src={protonmail} alt="" style={{width: '50px'}}/>
                            </div>

                            <div className="content">
                                <h1 style={{margin: 0, color: '#333366'}}>Email</h1>
                                <span>jogutu@pm.me</span>
                            </div>

                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                 viewBox="0 0 48 48">
                                <g className="nc-icon-wrapper" fill="#444444">
                                    <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"></path>
                                </g>
                            </svg>
                        </a>

                        <a href="https://twitter.com/jeremiah_ogutu" className="twitter" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="icon">
                                <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd"
                                     clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.414">
                                    <path
                                        d="M16 3.038c-.59.26-1.22.437-1.885.517.677-.407 1.198-1.05 1.443-1.816-.634.37-1.337.64-2.085.79-.598-.64-1.45-1.04-2.396-1.04-1.812 0-3.282 1.47-3.282 3.28 0 .26.03.51.085.75-2.728-.13-5.147-1.44-6.766-3.42C.83 2.58.67 3.14.67 3.75c0 1.14.58 2.143 1.46 2.732-.538-.017-1.045-.165-1.487-.41v.04c0 1.59 1.13 2.918 2.633 3.22-.276.074-.566.114-.865.114-.21 0-.41-.02-.61-.058.42 1.304 1.63 2.253 3.07 2.28-1.12.88-2.54 1.404-4.07 1.404-.26 0-.52-.015-.78-.045 1.46.93 3.18 1.474 5.04 1.474 6.04 0 9.34-5 9.34-9.33 0-.14 0-.28-.01-.42.64-.46 1.2-1.04 1.64-1.7z"
                                        fillRule="nonzero"/>
                                </svg>
                            </div>

                            <div className="content">
                                <h1  style={{margin: 0}}>Twitter</h1>
                                <span>@jeremiah_ogutu</span>
                            </div>

                            <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                 viewBox="0 0 48 48">
                                <g className="nc-icon-wrapper" fill="#444444">
                                    <path d="M17.17 32.92l9.17-9.17-9.17-9.17L20 11.75l12 12-12 12z"></path>
                                </g>
                            </svg>
                        </a>
                    </nav>
                </div>
            </div>
        )
    }
}

export default Contact
