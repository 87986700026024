import React, {Component} from 'react';

import {
    Card, CardTitle,
    CardText, CardActions, Button
} from 'react-mdl';

class Project extends Component {
    render() {
        return  <Card shadow={5} style={{minWidth: '450', margin: '10px'}}>
            <a href={this.props.liveUrl} style={{textDecoration: 'none', outline: 'none'}} target='_blank' rel="noopener noreferrer">
            <CardTitle style={{
                color: '#fff',
                height: '176px',
                background: `url(${this.props.imageUrl}) center / cover`
            }}><h2 className={"mdl-card__title-text"} >{this.props.name}</h2>  </CardTitle>
            </a>
            <CardText style={{fontWeight: 600}}>
               {this.props.description}
            </CardText>
            <CardActions border>
                <a href={this.props.githubUrl} target='_blank' rel="noopener noreferrer">
                    <Button colored>Github</Button>
                </a>
                <a href={this.props.liveUrl} target='_blank' rel="noopener noreferrer">
                <Button colored>Demo</Button>
                </a>
            </CardActions>
        </Card>
    }
}

export default Project
