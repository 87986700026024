import React, {Component} from 'react';
import {
    Tabs, Tab, Grid,
    Cell
} from 'react-mdl'
import classes from './projects.module.css'
import Project from "../Project/project";

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        }
    }

    toggleApps() {
        if (this.state.activeTab === 0) {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={classes["projects-grid"]}>
                        <Project
                            imageUrl='https://portfolio-jeremiah.s3.ca-central-1.amazonaws.com/ecommerce.jpg'
                            description='E-commerce App'
                            githubUrl='https://github.com/jeremiahogutu/e-commerce-app'
                            liveUrl='https://ecommerce.jeremiahogutu.ca/'
                        />
                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/videos_search.png'
                            description='Videos Search App'
                            githubUrl='https://github.com/jeremiahogutu/videos-search'
                            liveUrl='https://videosearch.jeremiahogutu.ca'
                        />
                        <Project
                            imageUrl='https://portfolio-jeremiah.s3.ca-central-1.amazonaws.com/weather.png'
                            description='Weather App'
                            githubUrl='https://github.com/jeremiahogutu/weather'
                            liveUrl='https://weather.jeremiahogutu.ca'
                        />

                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/image-search.png'
                            description='Image Search App'
                            githubUrl='https://github.com/jeremiahogutu/pics'
                            liveUrl='https://picsearch.jeremiahogutu.ca'
                        />

                        <Project
                            imageUrl='https://portfolio-jeremiah.s3.ca-central-1.amazonaws.com/social_network.png'
                            description='Social Network App'
                            githubUrl='https://github.com/jeremiahogutu/social-network'
                            liveUrl='https://social.jeremiahogutu.ca'
                        />

                        <Project
                            imageUrl='https://portfolio-jeremiah.s3.ca-central-1.amazonaws.com/recipes.png'
                            description='Recipes App'
                            githubUrl='https://github.com/jeremiahogutu/recipes'
                            liveUrl='https://recipes.jeremiahogutu.ca'
                        />
                        <Project
                            imageUrl='https://portfolio-jeremiah.s3.ca-central-1.amazonaws.com/rebound.png'
                            description='Rebound Game'
                            githubUrl='https://github.com/jeremiahogutu/rebound-game'
                            liveUrl='https://rebound.jeremiahogutu.ca'
                        />
                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/seasons.png'
                            description='Seasons App'
                            githubUrl='https://github.com/jeremiahogutu/seasons'
                            liveUrl='https://seasons.jeremiahogutu.ca'
                        />
                    </div>
                </div>


            )
        } else if (this.state.activeTab === 1) {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={classes["projects-grid"]}>
                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/sevenWonder.png'
                            description='Seven Wonders'
                            githubUrl='https://github.com/jeremiahogutu/seven-wonders'
                            liveUrl='https://jeremiahogutu.github.io/seven-wonders'
                        />
                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/toronto.png'
                            description='Parallax Website'
                            githubUrl='https://github.com/jeremiahogutu/parallax-website'
                            liveUrl='https://jeremiahogutu.github.io/parallax-website/'
                        />
                        <Project
                            imageUrl='https://s3.ca-central-1.amazonaws.com/portfolio-jeremiah/event.png'
                            description='Event Website'
                            githubUrl='https://github.com/jeremiahogutu/Event_registration'
                            liveUrl='https://jeremiahogutu.github.io/Event_registration/'
                        />
                    </div>
                </div>
            )
        } else if (this.state.activeTab === 2) {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={classes["projects-grid"]}>
                        <Project
                            name='React Native'
                            description='Coming Soon'
                        />
                        <Project
                            name='React Native'
                            description='Coming Soon'
                        />
                        <Project
                            name='React Native'
                            description='Coming Soon'
                        />
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={classes["category-tabs"]}>
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({activeTab: tabId})} ripple>
                    <Tab>Web Apps</Tab>
                    <Tab>Websites</Tab>
                    <Tab>Native Apps</Tab>
                </Tabs>
                <Grid>
                    <Cell col={12}>
                        <div className="content">
                            {this.toggleApps()}
                        </div>
                    </Cell>
                </Grid>
            </div>
        )
    }
}

export default Projects
